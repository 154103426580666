<template>
  <div>
    <div class="section-title section-title_app">
      <h3>Captura de orden</h3>
    </div>
    <div class="column is-12 has-background-white">
      <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 10px;">
        <b-tooltip
            v-if="$alfalab.canAskPatientSign(this)"
            label="Documentos"><b-button icon-left="file" type="is-primary" @click="$store.dispatch('setShowDocumentsModal', true)"></b-button></b-tooltip>
      </div>
    </div>
    <div class="column is-12" v-show="! hasPatient">
      <div class="is-divider" data-content="Paciente"></div>
      <select-patient class="columns"
                      :patient.sync="data.patient"
                      :has-company="hasCompany"
                      @selectOrder="val => $emit('setData', val)"
      ></select-patient>
    </div>
  </div>
</template>

<script>
import SelectPatient from "@/components/patient/SelectPatient";
import {mapGetters,mapState} from "vuex";

export default {
  name: "FormOrderForeignWork",
  components: {
    SelectPatient
  },
  props: {
    data: Object,
    errors: Array|Object,
    readOnly: Boolean,
    noMedical: Boolean,
    saved: {
      type: Object,
      default: {
        studies: false,
        company: false
      }
    },
  },
  data(){

  },
  computed: {
    ...mapState('order', [
      'company', 'medical'
    ]),
    ...mapGetters('order', [
      'hasCompany', 'hasPatient'
    ]),
  }
}
</script>

<style scoped>

</style>