<template>
  <div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <b-tooltip label="Primer orden">
            <b-button icon-left="circle-arrow-left"
                      type="is-primary"/>
          </b-tooltip>
          <b-tooltip label="Orden anterior">
            <b-button icon-left="arrow-left"
                      type="is-primary"/>
          </b-tooltip>
          <b-tooltip label="Orden siguiente">
            <b-button icon-left="arrow-right"
                      type="is-primary"/>
          </b-tooltip>
          <b-tooltip label="Última orden">
            <b-button icon-left="circle-arrow-right"
                      type="is-primary"/>
          </b-tooltip>
          <b-tooltip label="Filtrar búsqueda">
            <b-button icon-left="search" type="is-primary">
          </b-button></b-tooltip>
          <data-order-foreign-work></data-order-foreign-work>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FormOrderForeignWork from "@/components/foreign-work/FormOrderForeignWork";
import DataOrderForeignWork from "@/components/foreign-work/DataOrderForeignWork";
import {mapState, mapGetters} from "vuex";

export default {
  name: "Index2",
  components: {
    FormOrderForeignWork,
    DataOrderForeignWork
  },
  props: {
    noMedical: true,
    saved: {
      studies: false,
      company: false
    },
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapState('order', [
        'patient'
    ]),
    ...mapGetters('order',[
        'hasCompany', 'hasPatient'
    ])
  },
  methods: {

  }
}
</script>

<style scoped>

</style>